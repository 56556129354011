import { AppState } from '@sod/store/app.state';
import { NgModule, importProvidersFrom } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { RouterModule } from '@angular/router';
import { HTTP_INTERCEPTORS, HttpClientModule, provideHttpClient } from '@angular/common/http';
import { ApiUrl, AuthInterceptor } from './shared';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { BrowserAnimationsModule, provideAnimations } from '@angular/platform-browser/animations';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { environment } from '../../environments/environment';
import { LoginComponent } from './auth/login/login.component';
import { ErrorComponent } from './error/error.component';
import { ReactiveFormsModule } from '@angular/forms';
import { VerificationCodeComponent } from './auth/verification-code/verification-code.component';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { NgxsModule } from '@ngxs/store';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { PortalSelectionComponent } from './auth/portal-selection/portal-selection.component';
import { OverlayContainer, FullscreenOverlayContainer} from '@angular/cdk/overlay';
import { WrapperLogoComponent } from './shared/components/wrapper-logo/wrapper-logo.component';
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ErrorComponent,
    VerificationCodeComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    PortalSelectionComponent
  ],
  imports: [
    BrowserModule,
    RouterModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ReactiveFormsModule,
    SharedModule,
    WrapperLogoComponent,
    NgxsModule.forRoot([AppState], { developmentMode: !environment.production, }),
    NgxsStoragePluginModule.forRoot({ key: [AppState], }),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true, },
    { provide: ApiUrl.BASE_API_URL, useValue: environment.URI },
    { provide: ApiUrl.STREAM_API_URL, useValue: environment.STREAM_SERVER },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline', floatLabel: 'always' } },
    { provide: OverlayContainer, useClass: FullscreenOverlayContainer },
    { provide: MAT_DATE_FORMATS, useValue: MAT_DATE_FORMATS },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    provideAnimations(),
    provideHttpClient(),
    importProvidersFrom(MatNativeDateModule),
  ],

  bootstrap: [AppComponent]
})
export class AppModule { }
