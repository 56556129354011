<div class="warpper-forgot">
  <sod-wrapper-logo></sod-wrapper-logo>
  <div class="form-warpper">
    <div class="form">
      <span class="title">Forgot password?</span>
      <form
        [formGroup]="formForgot"
        (ngSubmit)="onFormForgot()"
      >
        <div class="form-input">
          <div class="wrapper-input">
            <span>Email <strong class="icon-required">*</strong></span>
            <input
              class="input-form"
              matInput
              required
              formControlName="email"
            />
          </div>
          <div
            class="text-error"
            *ngIf="
              formForgot.get('email')?.hasError('required') &&
              formForgot?.get('email')?.touched
            "
          >
            Email is required.
          </div>
        </div>
        <div class="action-form">
          <button
            class="btn btn-primary"
            type="submit"
            [disabled]="formForgot.invalid"
          >
            Confirm</button
          >
          <button
            class="btn back-login"
            [routerLink]="['/login']"
          >
            Back to login
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
