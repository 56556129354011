import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ErrorResponse } from '@sod/core/models';
import { AuthService } from '@sod/services/auth.service';
import { PopupService } from '@sod/services/popup.service';
import { regex } from '@sod/shared/constants';

@Component({
  selector: 'sod-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrl: './forgot-password.component.scss',
})
export class ForgotPasswordComponent {
  formForgot: FormGroup;
  constructor(
    private _fb: FormBuilder,
    public authService: AuthService,
    private toast: PopupService
  ) {
    this.formForgot = this._fb.group({
      email: ['', [Validators.required, Validators.pattern(regex.email)]],
    });
  }
  onFormForgot() {
    if (this.formForgot.valid) {
      console.log({ formForgot: this.formForgot.value });
      this.authService.forgotPassword(this.formForgot.value.email).subscribe({
        next: (res) => {
          this.toast.showToast('Email send', 'success');
          this.toast.showToast('Success', 'success');
        },
        error: (res: HttpErrorResponse) => {
          console.log({ res });

          const error = res.error as ErrorResponse;
          this.toast.showToast(error?.detail || 'Error', 'error');
        },
      });
    }
  }
}
