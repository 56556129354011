import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authGuard } from '@sod/shared/guards';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { PortalSelectionComponent } from './auth/portal-selection/portal-selection.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { ErrorComponent } from './error/error.component';

const appRoutes: Routes = [
  { path: '', redirectTo: 'portal-selection', pathMatch: 'full' },
  { path: 'login', loadChildren: () => import('./auth/auth.module').then(x => x.AuthModule) },
  { path: 'auth', loadChildren: () => import('./auth/auth.module').then(x => x.AuthModule) },
  { path: 'logout', loadComponent : () => import('./auth/logout/logout.component').then(x => x.LogoutComponent) },
  { path: 'error', loadComponent : () => import('./error/landing/error-landing-page.component').then(x => x.ErrorLandingPageComponent) },
  { path: 'landing/rank-code', loadComponent : () => import('./landing-pages/rank-code/rank-code.component').then(x => x.RankCodePageComponent) },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'reset-password', component: ResetPasswordComponent },
  { path: 'portal-selection', component: PortalSelectionComponent, canActivate: [authGuard] },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then((x) => x.AdminModule),
    canActivate: [authGuard],
  },

  {
    path: 'learning',
    loadChildren: () => import('./learning/learning.module').then((x) => x.LearningModule),
    canActivate: [authGuard],

  },
  { path: '**', component: ErrorComponent, pathMatch: 'full' },

];
@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
