import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { Roles } from '@sod/core/index';
import { Principal } from '@sod/core/models';
import { LoginSite } from '@sod/store/app.action';
import { STATE_TOKEN } from '@sod/store/app.state.model';

@Component({
  selector: 'sod-portal-selection',
  templateUrl: './portal-selection.component.html',
  styleUrl: './portal-selection.component.scss'
})
export class PortalSelectionComponent {
  constructor(private store: Store, private router: Router) { }
  setSiteAdmin() {
    const stateToken = this.store.selectSnapshot(STATE_TOKEN);
    const principal = new Principal(stateToken.token)
    if(principal.IsRole(Roles.SysAdmin) && principal.Role().length === 1){
      this.router.navigate([`/admin/setting/users-permission`]);
    }
    this.store.dispatch(new LoginSite('admin'))
  }
  setSiteLearning() {
    this.store.dispatch(new LoginSite('learning'))
  }
}
