import { ChangeDetectorRef, Component, inject} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HeaderService } from './services/header.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { delay, finalize, tap } from 'rxjs';
@Component({
  selector: 'sod-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',

})
export class AppComponent {

  private readonly header = inject(HeaderService);

  showLoading$ = this.header.loading$.pipe(
    takeUntilDestroyed(),
    delay(0),
    finalize(() => { this.cdr.detectChanges() }),
  );
  constructor(private router: Router, private cdr: ChangeDetectorRef) {
  }



}
