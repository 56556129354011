<div class="warpper-reset">
  <sod-wrapper-logo></sod-wrapper-logo>
  <div class="form-warpper">
    <div class="form">
      <span class="title">Reset Password</span>
      <form [formGroup]="formResetPass" (ngSubmit)="onformResetPass()">
        <div class="form-input">
          <div class="wrapper-input">
            <span>Password <strong class="active-email">*</strong></span>
            <div class="input-password">
              <input
                class="input-form"
                type="{{ toglePassword.password }}"
                matInput
                required
                formControlName="password"
              />
              <div class="icon-password" (click)="onToglePassword('password')">
                <img
                  *ngIf="toglePassword.password === 'password'"
                  src="/assets/svg/show-password.svg"
                  alt="show hide"
                />
                <img
                  *ngIf="toglePassword.password === 'text'"
                  src="/assets/svg/hide-password.svg"
                  alt="password hide"
                />
              </div>
            </div>
          </div>
          <div
            class="text-error"
            *ngIf="
              formResetPass.get('password')?.hasError('required') &&
              formResetPass?.get('password')?.touched
            "
          >
            Password is required.
          </div>
        </div>
        <div class="form-input">
          <div class="wrapper-input">
            <span>New password <strong class="active-email">*</strong></span>
            <div class="input-password">
              <input
                class="input-form"
                type="{{ toglePassword.newPassword }}"
                matInput
                required
                formControlName="newPassword"
              />
              <div class="icon-password" (click)="onToglePassword('newPassword')">
                <img
                  *ngIf="toglePassword.newPassword === 'password'"
                  src="/assets/svg/show-password.svg"
                  alt="show hide"
                />
                <img
                  *ngIf="toglePassword.newPassword === 'text'"
                  src="/assets/svg/hide-password.svg"
                  alt="password hide"
                />
              </div>
            </div>
          </div>
          <div
            class="text-error"
            *ngIf="
              formResetPass.get('password')?.hasError('required') &&
              formResetPass?.get('password')?.touched
            "
          >
            Password is required.
          </div>
        </div>
        <ul class="validation-checks">
          <li [ngClass]="{ active: hasUppercaseLetter() }">
            First letter capital
          </li>
          <li [ngClass]="{ active: hasNumber() }">At least one number</li>
          <li [ngClass]="{ active: specialCharacter() }">
            At least one special character
          </li>
          <li [ngClass]="{ active: minLength() }">At least 8 characters</li>
        </ul>

        <div class="form-input">
          <div class="wrapper-input">
            <span
              >Comfirm new password <strong class="active-email">*</strong></span
            >
            <div class="input-password">
              <input
                class="input-form"
                type="{{ toglePassword.reEnterPassword }}"
                matInput
                required
                formControlName="reEnterPassword"
              />
              <div
                class="icon-password"
                (click)="onToglePassword('reEnterPassword')"
              >
                <img
                  *ngIf="toglePassword.reEnterPassword === 'password'"
                  src="/assets/svg/show-password.svg"
                  alt="show hide"
                />
                <img
                  *ngIf="toglePassword.reEnterPassword === 'text'"
                  src="/assets/svg/hide-password.svg"
                  alt="password hide"
                />
              </div>
            </div>
          </div>
          <div
            class="text-error"
            *ngIf="
              formResetPass.get('reEnterPassword')?.hasError('required') &&
              formResetPass?.get('reEnterPassword')?.touched
            "
          >
            Password is required.
          </div>
          <div
            class="text-error"
            *ngIf="
              formResetPass.hasError('passwordMismatch') &&
              formResetPass.get('re_password')?.touched
            "
          >
            Passwords do not match.
          </div>
        </div>
        <button
        class="btn btn-primary btn-confirm"
        type="submit"
        [disabled]="formResetPass.invalid"
      >
        Confirm</button
      >
      </form>
    </div>
  </div>
</div>
