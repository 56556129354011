<div class="wrapper">
  <sod-wrapper-logo></sod-wrapper-logo>
  <div class="form-wrapper">
    <span class="title">Portal Selection</span>
    <div class="list-router">
      <button class="btn" [routerLink]="['/admin']" (click)="setSiteAdmin()">Admin</button>
      <button class="btn" [routerLink]="['/learning']" (click)="setSiteLearning()">User</button>
    </div>

  </div>
</div>
