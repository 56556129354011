import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ErrorResponse } from '@sod/core/models';
import { ForgetType } from '@sod/models/auth.model';
import { AuthService } from '@sod/services/auth.service';
import { PopupService } from '@sod/services/popup.service';
import { regex } from '@sod/shared/constants';

@Component({
  selector: 'sod-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrl: './reset-password.component.scss'
})
export class ResetPasswordComponent {
  formResetPass: FormGroup;
  toglePassword: any = {
    password: 'password',
    newPassword: 'password',
    reEnterPassword: 'password',

  };
  constructor(private _fb: FormBuilder, private route: ActivatedRoute, public authService: AuthService,private toast: PopupService,private router: Router) {
    this.formResetPass = this._fb.group({
      password: ['', [Validators.required]],
      newPassword: ['', [Validators.required, Validators.pattern(regex.password)]],
      reEnterPassword: ['', [Validators.required, Validators.pattern(regex.password)]],


    });
  }
  onToglePassword(type: string) {
    if (type === 'password') {
      if (this.toglePassword.password === 'password') {
        this.toglePassword.password = 'text'
      } else {
        this.toglePassword.password = 'password'
      }
    } else if (type === 'newPassword') {
      if (this.toglePassword.newPassword === 'password') {
        this.toglePassword.newPassword = 'text'
      } else {
        this.toglePassword.newPassword = 'password'
      }
    } else {
      if (this.toglePassword.reEnterPassword === 'password') {
        this.toglePassword.reEnterPassword = 'text'
      } else {
        this.toglePassword.reEnterPassword = 'password'
      }
    }

  }

  onformResetPass() {
    const body: ForgetType = {
      ...this.formResetPass.value,
      token: this.route.snapshot.queryParams['t']
    }
    if (this.formResetPass.valid) {
      this.authService.resetPassword(body).subscribe({
        next: res => {
          this.router.navigate(['/login']);
        }, error: (res: HttpErrorResponse) => {

          const error = res.error as ErrorResponse;
          this.toast.showToast(error?.detail || "Error", 'error');
        }
      })
    }
  }

  hasUppercaseLetter(): boolean {
    const password = this.formResetPass.get('newPassword')?.value;

    return /[A-Z]/.test(password);
  }
  hasNumber(): boolean {
    const password = this.formResetPass.get('newPassword')?.value;

    return /\d/.test(password);
  }
  specialCharacter(): boolean {
    const password = this.formResetPass.get('newPassword')?.value;

    return /[!@#$%^&*]/.test(password);
  }
  minLength(): boolean {

    return this.formResetPass.get('newPassword')?.value?.length >= 8
  }
}
